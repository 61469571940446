






























































import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import GeneralHelper from '@/shared/helpers/general';
import paymentsService from '@/services/sparkecommerce/payments';

@Component
export default class PaymentStatusBagde extends Vue {
  @Prop({ type: Object, required: true }) sale!: any;

  public showCpf: boolean = false;

  isLoadingRequest = false;

  async SendRefundRequest() {
    const refundPaymentId = this.sale.refund_payment_id || this.sale.id;
    this.isLoadingRequest = true;

    try {
      const { data } = await paymentsService.refund(refundPaymentId);
      this.sale.status = data.status;
      this.sale.enable_refund = data.enable_refund;
      this.sale.refund_request_at = data.refund_request_at;
      this.$emit('close');
    } catch {
      this.$emit('error');
    } finally {
      this.isLoadingRequest = false;
    }
  }

  formatCPF(cpf) {
    return cpf ? GeneralHelper.cpf(cpf, !this.showCpf) : '-';
  }

  get descriptionText() {
    return this.$t('sparkpay.sales.refund-sidebar.description');
  }

  get limitDate() {
    return this.sale.refund_limit_date ? dayjs(this.sale.refund_limit_date).format('DD/MM/YYYY') : '—';
  }

  get warningText() {
    return this.$t('sparkpay.sales.refund-sidebar.warning');
  }
}
